/* body {
    background-image: url(./static/notebook_paper.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
} */

.box h1,
.box div.item,
p.freshness-blurb,
.box input {
  /* font-family: "Gochi Hand", cursive; */
  /* font-family: "Caveat", cursive; */
  /* font-family: "Gloria Hallelujah", cursive; */
  /* font-family: "Handlee", cursive; */
  font-family: "Hind", sans-serif;
  color: rgb(69, 69, 69);
}

.box h1 {
  font-size: 250%;
  text-align: center;
}
div.list-container input {
  font-size: 130%;
}
input.edit-item {
  font-size: 120%;
}
.box div.item {
  font-size: 130%;
  padding-top: 15px;
  padding-bottom: 15px;
  /* padding-bottom: 20px; */
  border-bottom: 1px solid #efefef;
}
.box input {
  /* font-size: 120%; */
}

.box {
  /* margin: 5% 20%; */
}
.box div.item p {
  margin-bottom: 5px;
  line-height: 1;
}
.box div.item p.metadata {
  margin-left: 10px;
  font-family: Helvetica, sans-serif;
  font-size: 10px;
  opacity: 0.3;
}
.box div.item p.metadata:hover {
  opacity: 1;
}

p.freshness-blurb {
  text-align: center;
  font-style: italic;
  margin: 100px 0;
}

.notification.undo-notification {
  position: absolute;
  top: 12px;
  opacity: 0.95;
  padding: 0.7rem;
}
/* .notification.undo-notification button {
  opacity: 0.95;
} */

figure.avatar img {
  border-radius: 50%;
}

.breadcrumb li a {
  padding: 0 0.25em;
}

@media (min-width: 768px) {
  .box h1 {
    font-size: 400%;
    text-align: center;
  }
  .box div.item {
    font-size: 200%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  div.list-container input {
    font-size: 150%;
  }
  /* p.freshness-blurb {
    margin: 100px;
  } */

  .breadcrumb li a {
    padding: 0 0.75em;
  }

  .box {
    margin: 20px 150px;
  }
  .box div.item p {
    /* margin-bottom: 0 !important; */
  }
  .box div.item p.metadata {
    font-family: Helvetica, sans-serif;
    font-size: 10px;
    opacity: 0.3;
  }
  .box div.item p.metadata:hover {
    opacity: 1;
  }
}

.strikeout {
  /* font-size: 4em; */
  position: relative;
}
.strikeout::after {
  border-bottom: 0.125em solid rgba(255, 0, 0, 0.5);
  content: "";
  left: 0;
  line-height: 1em;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* div.list-container {
  height: 420px;
}
div.list-container .list-container-inner {
  max-height: 400px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  div.list-container {
    height: 100%;
  }
  div.list-container .list-container-inner {
    max-height: 600px;
    overflow-y: scroll;
  }
} */

.has-text-warning {
  /* color: rgb(243, 203, 24) !important; */
  color: rgb(255, 167, 60) !important;
}

.badge.is-badge-warning:not([data-badge])::after, .badge.is-badge-warning[data-badge]::after {
  background: rgb(255, 167, 60);
  /* color: rgba(0,0,0,.7); */
}


div.item p:before {
  content: "• ";
}
/* div.item p.strikeout:before {
  content: "☑";
} */


/* This is my solution to:
   https://github.com/derrickpelletier/react-loading-overlay/issues/33
  Seems to work for now.
   */
div._loading_overlay_wrapper {
  position: unset;
}
